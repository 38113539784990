"use client";

import { cn } from "@/lib/utils";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { SidebarTrigger } from "./ui/sidebar";
import KatanaDouble from "./images/KatanaDouble";
import { CgSidebarOpen } from "react-icons/cg";

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

export const Header = ({ children, className, ...rest }: HeaderProps) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    // Initial height update
    updateHeaderHeight();

    // Update height on window resize
    window.addEventListener("resize", updateHeaderHeight);

    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, []);

  return (
    <>
      <div style={{ height: `${headerHeight}px` }}></div>

      <header
        ref={headerRef}
        className={cn(
          "fixed top-0 z-[50] w-webkit-fill w-moz-available flex items-center  justify-between  shadow-md md:shadow-xl honk-gradient-1 border-b-2 border-honk-1 transition-all",
          className
        )}
        {...rest}
      >
        <SidebarTrigger className="h-full min-h-full hover:bg-transparent hover:brightness-125 transition-all w-fit flex items-start justify-start p-4">
          <CgSidebarOpen className=" transition-all stroke-black text-honk-3  stroke-[1] size-10" />
        </SidebarTrigger>

        <em className="font-honk not-italic h-fit flex flex-row items-center gap-4 p-2">
          <span className="text-right text-xl md:text-2xl whitespace-nowrap">
            SMART SAMURAI
            <br />
            KRZYSZTOF KUTNIOWSKI
          </span>
          <KatanaDouble className=" size-14" />
        </em>
      </header>
    </>
  );
};
