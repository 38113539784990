"use client";

import KatanaLogo from "@/app/assets/katanalogonobg767.webp";
import { cn } from "@/lib/utils";
import Image from "next/image";

import React from "react";

type Props = { className: string };

const KatanaDouble = ({ className }: Props) => {
  return (
    <Image
      alt="logo double katana no background"
      src={KatanaLogo}
      className={cn(className)}
      priority
    />
  );
};

export default KatanaDouble;
